/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import {
  ContainerCenter,
  FlexTitle,
  FrameContainer,
  IFrame,
  Image,
  TitleLabel,
} from "./Styles";
import Close from "../../Assets/images/CloseDoc.png";
import { useEffect } from "react";
import { GetUpdateWatched } from "../../StateManagement/Reducers/HomeWorkState";
import { useDispatch } from "react-redux";
import { memo } from "react";

const DocumentView = ({ url, docTitle, id, clearId, setMyState, myState }) => {
  let backurl = "";
  let firstDot = window.location.hostname.split(".");
  let DBhost;
  if (firstDot[0] !== "localhost") {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    backurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBhost = "default";
  } else {
    DBhost = firstDot[0];
  }
  const B_Url = backurl + "/sites/default/Education_Files/" + url;
  const dispatch = useDispatch(id);
  useEffect(() => {
    if (id) {
      dispatch(GetUpdateWatched(parseInt(id, 10)));
    }
    return () => {
      clearId(null);
    };
  }, [id]);
  return (
    myState.showdoc && (
      <ContainerCenter>
        <FlexTitle border>
          <Image
            src={Close}
            cursor="pointer"
            onClick={() => setMyState({ ...myState, showdoc: false })}
          />
          <TitleLabel
            fontFamily="Inter"
            fontSize="24px"
            fontHeight="29px"
            margin
          >
            {docTitle}
          </TitleLabel>
        </FlexTitle>
        <FrameContainer>
          <IFrame src={B_Url} loading="eager" height="84%" />
        </FrameContainer>
      </ContainerCenter>
    )
  );
};

export default memo(DocumentView);
